import React from 'react'
import SignupCm from '../components/Signup/Signup/SignupCm';

export default function Signup() {
  return (
    <div>
      <SignupCm/>
    </div>
  );
}
