const chatbotInfo = [
    {
        name: 'Simmaeum',
        title: '나는 공감을 잘해줘 ><', 
        info: '말과 조언을 많이 해주는 편은 아니지만, 내 똘망똘망한 눈으로\n 너의 상황을 잘 관찰하고 너의 마음에 공감하며 위로를 잘 해줄거야.',
        mobile: '말과 조언을 많이 해주는 편은 아니지만, 똘망똘망한\n 눈으로 상황을 잘 관찰하고 공감하며 위로를 잘 해줄거야.',

    }, {
        name: 'Banbani',
        title: '나는 공감과 조언을 모두 해주는편이야 :)',
        info: '너의 이야기를 잘 듣고 상황 판단과 눈치가 있는 편이라\n 적절한 공감과 위로, 조언까지 해줄 것 같아.',
        mobile: '너의 이야기를 잘 듣고 상황 판단과 눈치가 있는 편이라\n 적절한 공감과 위로, 조언까지 해줄 것 같아.',
    }, {
        name: 'Neuranee',
        title: '나는 상황에 대해 조언을 잘해줘 ^^',
        info: '너에게 있었던 일에 대해 객관적으로 파악하고 다양한 입장에서\n 너에게 도움이 되는 이야기를 해줄 수 있을 것 같아.',
        mobile: '너에게 있었던 일에 대해 객관적으로 파악하고 다양한\n 입장에서 도움이 되는 이야기를 해줄 수 있을 것 같아.',
    }    
]

export default chatbotInfo;